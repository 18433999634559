:host {
  display: block;
  font-family: var(--sc-font-sans);
  --sc-toggle-padding: var(--sc-spacing-medium);
}

::slotted([slot='summary']) {
  display: flex;
  align-items: center;
  flex-direction: flex-start;
  gap: var(--sc-spacing-x-small);
}

.details {
  border-radius: var(--sc-border-radius-medium);
  background-color: var(--sc-toggle-background-color, var(--sc-color-white));
  overflow-anchor: none;

  &__radio {
    flex: 0 0 auto;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--sc-input-background-color);
    color: transparent;
    border-radius: 50%;
    border: solid var(--sc-toggle-border-width, var(--sc-input-border-width)) var(--sc-toggle-border-color, var(--sc-input-border-color));
    background-color: var(--sc-input-background-color);
    display: inline-flex;
    color: transparent;
    width: var(--sc-toggle-radio-size, var(--sc-radio-size));
    height: var(--sc-toggle-radio-size, var(--sc-radio-size));
    transition: var(--sc-input-transition, var(--sc-transition-medium)) border-color, var(--sc-input-transition, var(--sc-transition-medium)) background-color,
      var(--sc-input-transition, var(--sc-transition-medium)) color, var(--sc-input-transition, var(--sc-transition-medium)) box-shadow;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
.details--open .details__radio {
  color: var(--sc-color-white);
  border-color: var(--sc-color-primary-500);
  background-color: var(--sc-color-primary-500);
}

.details:not(.details--borderless) {
  border: solid 1px var(--sc-toggle-border-color, var(--sc-color-gray-200));
}
.details--disabled {
  opacity: 0.5;
}
.details__header {
  display: flex;
  align-items: center;
  border-radius: inherit;
  padding: var(--sc-toggle-header-padding, var(--sc-toggle-padding));
  user-select: none;
  cursor: pointer;
  color: var(--sc-toggle-header-color, var(--sc-input-label-color));
  gap: 0.75em;
}
.details__header:focus {
  box-shadow: var(--sc-focus-ring);
}
.details__header:focus-visible {
  box-shadow: var(--sc-focus-ring);
}
.details--disabled .details__header {
  cursor: not-allowed;
}
.details--disabled .details__header:focus-visible {
  outline: none;
  box-shadow: none;
}
.details__summary {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
}
.details__summary-icon {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  transition: var(--sc-transition-medium) transform ease;
}
.details--open {
  .details__summary-icon {
    transform: rotate(90deg);
  }
}
.details__content {
  padding: var(--sc-toggle-content-padding, var(--sc-toggle-padding));
  padding-top: calc(var(--sc-toggle-content-padding, var(--sc-toggle-padding)) / 4);
}
.details--shady {
  .details__body {
    border-top: solid var(--sc-input-border-width) var(--sc-input-border-color);
    background: var(--sc-toggle-shady-color, var(--sc-color-gray-50));
  }
  .details__content {
    padding-top: var(--sc-toggle-content-padding, var(--sc-toggle-padding));
  }
}
